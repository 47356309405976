import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='FlexiBlog theme comes with a pre-made contact form component.
					You can integrate this form with serverless services such as Formspree, Getform,
					FormKeep and others to receive form submissions via email.'
        />
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.62062253982!2d77.5718955!3d12.9459064!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x91b59697af5877bb!2sDCT%20Academy%20-%20Full%20Stack%20Web%20Developer%20Course%20Training%20Institute%20in%20Bangalore!5e0!3m2!1sen!2sin!4v1595414472474!5m2!1sen!2sin" style={{width: 700, height:350, frameBorder:"0", "border":"0", allowfullscreen:"", ariaHidden:"false", tabindex:"0"}} title="DCT Academy"></iframe>
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar style={{paddingLeft: "2rem"}}>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </>
)
